import PropTypes from 'prop-types';
import '/assets/styles/layouts/sidemenu.scss';

// Sections
import Header from '/views/sections/header'
import Footer from '/views/sections/footer'

// Templates
import SidemenuTemplate from './template';
import classNames from 'classnames';

const SidemenuLayout = ({ children, pageClass, title, loading, menuItems, className, hideMenuItemsOnMobile }) => {
	return (
		<div className={classNames('page', 'layout-sidemenu', className, pageClass ? `page-${pageClass}`: null)}>
			<Header />
			<SidemenuTemplate
				loading={loading}
				hideMenuItemsOnMobile={hideMenuItemsOnMobile}
				menuItems={menuItems}
				title={title}>
				{children}
			</SidemenuTemplate>
			<Footer />
		</div>
	)
}

SidemenuLayout.propTypes = {
	children: PropTypes.node,
	pageClass: PropTypes.string,
	hideMenuItemsOnMobile: PropTypes.bool,
}

export default SidemenuLayout