import '/assets/styles/sections/header/header.scss';
import { createContext, useEffect, useMemo, useState } from 'react';
import loadable from '@loadable/component'
import classNames from 'classnames';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
// import AccountMenu from './partials/account-menu-desktop';

// Static Assets
import image_logo from '/assets/images/logo.svg';

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useApplication } from '/utilities/application';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';

// Services
import userServices from '/services/user';

// Lazy Components
const DesktopSearch = loadable(() => import('./partials/search-desktop'))
const DesktopAccountMenu = loadable(() => import('./partials/account-menu-desktop'))

export const HeaderContext = createContext({
	showDesktopMenu: false,
	showDesktopMenuOnce: false,
	setShowDesktopMenu: () => { }
});

export const Header = () => {
	const { desktop } = useBreakpoints();
	const { openModal } = useModals();
	const { activeRoute } = useNavigator();
	const { isError, isNotFound } = useGlobalState();
	const { isApplication } = useApplication();
	const { loggedIn } = useAuth();
	
	const [notificationCount, setNotificationCount] = useState(null);
	const [showDesktopMenu, setShowDesktopMenu] = useState(false);
	const [showAccountMenu, setShowAccountMenu] = useState(false);
	const [showLocationSelector, setShowLocationSelector] = useState(false);

	const getNotificationCount = () => {
		setNotificationCount(null);
		userServices.getNotificationCount().then(setNotificationCount).catch(() => {
		});
	}

	const onNotificationsRead = () => {
		userServices.readAllNotifications().then(() => {
			getNotificationCount();
		}).catch(() => {
		})
	}

	useEffect(() => {
		if(isApplication && loggedIn) {
			getNotificationCount();
		}
		else {
			setNotificationCount(null);
		}
	}, [loggedIn, isApplication])

	const contextApi = useMemo(() => ({
		showDesktopMenu, 
		setShowDesktopMenu,
		showAccountMenu,
		setShowAccountMenu,
		showLocationSelector,
		setShowLocationSelector,
		notificationCount,
	}), [
		showDesktopMenu, 
		setShowDesktopMenu,
		showAccountMenu,
		setShowAccountMenu,
		showLocationSelector,
		setShowLocationSelector,
		notificationCount,
	])

	return (
		<HeaderContext.Provider value={contextApi}>
			<header className={classNames('section', 'header', { 'no-bg': !isError && !isNotFound && activeRoute?.key === 'home' })}>
				<div className="wrapper header-wrap">
					<Link href="home" className="header-logo">
						<Img className="logo-image" lazy={false} alt="Logo" src={image_logo} />
					</Link>


					{/* <LocationSelector /> */}

					<div className="header-locationselector">
						<button
							className="locationselector-btn"
							onClick={() => { openModal('locations') }}>
							<Icon className="btn-pinicon" name="map-pin" />
							<span className="btn-branchname">Yer Seçimi</span>
							<Icon className="btn-switchicon" name="switch" />
							{desktop &&
								<span className="btn-switchtext">değiştir</span>
							}
						</button>
					</div>

					{desktop &&
						<>
							<DesktopSearch />
							<nav className="header-nav">
								<Link href="events" className="nav-link">
									<Icon className="link-icon" name="concert" />
									Tüm Etkinlikler
								</Link>
								<Link href="contact" className="nav-link">
									İletişim
								</Link>
							</nav>
							<DesktopAccountMenu />
						</>
					}

					{!desktop &&
						<>
							{(isApplication) &&
								<button
									onClick={() => {
										if(loggedIn) {
											openModal('notifications', { onStare: onNotificationsRead });
										}
										else {
											openModal('auth');
										}
									}}
									type="button"
									name="Bildirimler"
									className={classNames('header-notificationbtn', { highlight: !!notificationCount})}>
									<span className="notificationbtn-count">{notificationCount}</span>
									<Icon name="notification" />
								</button>
							}
							<button
								onClick={() => {
									openModal('search');
								}}
								type="button"
								name="Arama"
								className="header-searchbtn">
								<Icon name="search" />
							</button>
							<button
								onClick={() => {
									openModal('mobileMenu');
								}}
								type="button"
								name="Ana Menü"
								className="header-menubtn">
								<Icon name="menu-nav" />
							</button>
						</>
					}

				</div>
			</header>
		</HeaderContext.Provider>
	)
}

export default Header;