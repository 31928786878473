import '/assets/styles/sections/footer.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';

// Static Assets
import image_footer from '/assets/images/footer/image.png'
import image_footer_2x from '/assets/images/footer/image@2x.png'
import image_footer_mobile from '/assets/images/footer/image-mobile.png'
import image_footer_mobile_2x from '/assets/images/footer/image-mobile@2x.png'
import image_logo from '/assets/images/logo-plain.svg'

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useApplication } from '/utilities/application';

const Footer = ({ plain }) => {
	const { desktop } = useBreakpoints();
	const { isApplication } = useApplication();

	return (
		<footer className={classNames('section footer', { plain })}>
			{(!plain && !isApplication) &&
				<div className="footer-top">
					<Img
						src={image_logo}
						alt="IF Performance Hall"
						className="top-bg" />
					<div className="wrapper top-wrap">
						<div className="top-section">
							<strong className="section-title">
								Bizi Takip Et, Gecenin Kırmızısına Ortak Ol!
							</strong>

							<div className="section-text">IF Performance Hall’u sosyal medyada takip ederek gecenin nabzını <br />tut ve kırmızısına ortak ol!</div>

							<nav className="footer-social">
								<Link
									raw
									target="_blank"
									rel="noopener noreferrer"
									href={process.config.social.facebookUrl}>
									<Icon name="facebook" />
								</Link>
								<Link
									raw
									target="_blank"
									rel="noopener noreferrer"
									href={process.config.social.twitterUrl}>
									<Icon name="twitter" />
								</Link>
								<Link
									raw
									target="_blank"
									rel="noopener noreferrer"
									href={process.config.social.instagramUrl}>
									<Icon name="instagram" />
								</Link>
								<Link
									raw
									target="_blank"
									rel="noopener noreferrer"
									href={process.config.social.youtubeUrl}>
									<Icon name="youtube" />
								</Link>
							</nav>
						</div>
						<div className="top-section">
							<strong className="section-title">
								IF Bilet Destek
							</strong>

							<div className="section-text">Biletler hakkında tüm soru ve görüşlerinizi <br />whatsapp üzerinden bize yazabilirsiniz!</div>

							<a
								className="footer-whatsapplink"
								target="_blank"
								rel="noopener noreferrer"
								href={process.config.social.whatsappTicketSupportLink}>
								<Icon name="whatsapp" />
								<span>{process.config.social.whatsappTicketSupportText}</span>
							</a>
						</div>

						<Img
							srcSet={`${desktop ? image_footer_2x : image_footer_mobile_2x} 2x`}
							src={desktop ? image_footer : image_footer_mobile}
							alt="Gece IF'te Biter"
							className="top-image" />
					</div>
				</div>
			}
			<div className="footer-bottom">
				<div className="wrapper bottom-wrap">
					<nav className="footer-nav">
						<div className="nav-item">
							<Link
								params={{ slug: 'hakkimizda', id: 1 }}
								href="page">
								Hakkımızda
							</Link>
						</div>
						<div className="nav-item">
							<Link href="faq">Sıkça Sorulan Sorular</Link>
						</div>
						{/* <div className="nav-item">
							<Link href="superMuzikFest">Süper Müzik Fest</Link>
						</div> */}
						<div className="nav-item">
							<Link href="amateurBandForm">Sahne Senin Başvuru Formu</Link>
						</div>
						<div className="nav-item">
							<Link href="contact">İletişim</Link>
						</div>
					</nav>

					<span className="bottom-copyright">© Copyright - Tüm Hakları Saklıdır - IF Performance Hall</span>
				</div>
			</div>
		</footer>
	)
}

Footer.propTypes = {
	plain: PropTypes.bool,
}

export default Footer;