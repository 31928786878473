import '/assets/styles/layouts/sidemenu.scss';
import classNames from "classnames";
import { useMemo } from 'react';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link'
import Icon from '@wearetla/tla-essentials-tools/partials/icon'
import Placeholder from '/views/partials/placeholder'
import Loader from '/views/partials/loader';
import { Input } from '/views/partials/forms'

// Context
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';

export const SidemenuTemplate = ({ children, menuItems, title, loading, hideMenuItemsOnMobile, hideTitleOnMobile }) => {
	const { desktop, mobile } = useBreakpoints();
	const { getRoute, generateRoutePath, matchRoute, activeRoute, redirect } = useNavigator();

	const { mobileNavOptions, mobileNavSelection } = useMemo(() => {
		var mobileNavSelection = null;
		const mobileNavOptions = menuItems.filter(i => i).map(((item, nth) => {
			const selection = {
				...item,
				value: nth,
				label: item.title,
			}

			if(!mobileNavSelection && (!item.tag || item.tag === 'link')) {
				const route = item.route ?? getRoute(item.href);
				const routePath = generateRoutePath({ route, params: item.params });

				const match = matchRoute(routePath);

				if(activeRoute?.match?.url === match?.match?.url) {
					mobileNavSelection = selection;
				}
			}

			return selection;
		}));

		return {
			mobileNavOptions,
			mobileNavSelection,
		}
	}, [menuItems, activeRoute])

	return (
		<div className="section sidemenu-template wrapper loader-container">
			<Loader strict dark loading={!!loading} />
			{!loading &&
				<>
					{!(mobile && ((!title || hideTitleOnMobile) && (!menuItems || hideMenuItemsOnMobile))) &&
						<aside className="layout-sidemenu-aside">
							{title && !(mobile && hideTitleOnMobile) &&
								<>
									{mobile ?
										<h1 className="aside-title">{title}</h1>
										:
										<strong className="aside-title">{title}</strong>
									}
								</>
							}

							{(menuItems && desktop) &&
								<ul className="aside-menu">
									{menuItems.map((item, nth) => (
										<SidemenuItem item={item} key={nth} />
									))}
								</ul>
							}
							{(menuItems && mobile && !hideMenuItemsOnMobile) &&
								<Input
									type="select"
									onChange={(item) => {
										if(item?.href) {
											redirect(item.href, item.params ?? {});
										}
									}}
									options={mobileNavOptions}
									value={mobileNavSelection}
									className="aside-mobilenav" />
							}
						</aside>
					}
					<main className="layout-sidemenu-content">
						{children}
					</main>
				</>
			}
		</div>
	)
}

export default SidemenuTemplate

export const SidemenuItem = ({ item }) => {
	const { tag, active, className: rawClassName, title, icon, ...props } = item ?? {};
	const ElemTag = useMemo(() => {
		switch(tag) {
			case 'button': return 'button';
			default: return Link
		}
	}, [tag]);

	const className = useMemo(() => (
		classNames(
			'item-link',
			rawClassName,
			{
				active: active
			}
		)
	), [rawClassName, active]);

	return <li className="menu-item">
		{item ?
			<ElemTag className={className} {...props}>
				<span className="item-contentwrap">
					{!!icon &&
						<Icon className="item-icon" name={icon} />
					}
					{title}
				</span>
			</ElemTag>
			:
			<Placeholder light className={className} />
		}
	</li>
}